import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'spectre.css/dist/spectre.css';
import 'spectre.css/dist/spectre-icons.css'; /** optional (for-icons) **/
import 'spectre.css/dist/spectre-exp.css';  /** optional (for-experimentals) **/

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
