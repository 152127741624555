import "./App.css";
import React, { useRef, useEffect, useState } from "react";
import * as tf from "@tensorflow/tfjs";

function App() {
  // Neural networks used for prediction
  const [modelInference, setModelInference] = useState();
  const [modelGenerative, setModelGenerative] = useState();

  // Use this to check if you can run the models
  const [modelsReady, setmodelsReady] = useState(false);

  // Results to display
  const [resultAE, setResultAE] = useState([
    "No prediction made yet",
    "Run the model and make a prediction",
  ]);

  const DEFAULT_TILES_COLORS = [
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
  ];

  const EMPTY_ARRAY = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  // Styles tiles
  const [patientArray, setPatientArray] = useState([
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
  ]);
  const [resultArray, setResultArray] = useState([
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
    "white",
  ]);

  var inputData = useRef([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const THRESHOLD_PROBA_EVENT = 0.8;
  const THRESHOLD_NORM_RESULT = 2.05;

  // We need to define a L2 regularizer, otherwise we can't load the model
  class L2 {
    static className = "L2";

    constructor(config) {
      return tf.regularizers.l1l2(config);
    }
  }
  tf.serialization.registerClass(L2);

  /**
   * Update input data based on user interaction
   */

  function _updateInputData(idTile, mode = "switch", reset = false) {
    // 0 if 1, 1 if 0
    let newPatientArray = patientArray;

    if (reset === true) {
      newPatientArray = Object.assign(newPatientArray, DEFAULT_TILES_COLORS);
      inputData.current = Object.assign(inputData.current, EMPTY_ARRAY);
    }

    for (var i = 0; i < idTile.length; i++) {
      if (mode === "switch") {
        inputData.current[idTile[i]] = Math.abs(
          inputData.current[idTile[i]] - 1
        );
      } else if (mode === "ecrase") {
        inputData.current[idTile[i]] = 1;
      }

      if (inputData.current[idTile[i]] === 0) {
        newPatientArray[idTile[i]] = "white";
      } else {
        newPatientArray[idTile[i]] = "black";
      }
    }

    setPatientArray([...newPatientArray]);
  }
  const clickOnDiv = (idTile) => () => {
    if (!Array.isArray(idTile)) {
      idTile = [idTile];
    }
    _updateInputData(idTile);
  };

  /**
   * Generate a sick patient
   */
  function generateSickExample() {
    _updateInputData([0, 2, 6, 8, 12], "ecrase", true);
  }

  /**
   * Generate a healthy patient
   */
  function generateHealthyExample() {
    _updateInputData([8, 13], "ecrase", true);
  }

  /**
   * Run AutoEncoder and make a prediction
   */
  function runModel() {
    var textArrayResult;

    // Input value
    var oldArrayValue = resultArray;

    // Input
    let originalValues = tf.tensor2d(inputData.current, [1, 16]);

    // Encoder decoder
    let encodedValues = tf.split(modelInference.predict(originalValues), 2, 1);
    let decodedValues = tf.sigmoid(modelGenerative.predict(encodedValues[0]));

    // Distance between input and decoded value
    let normResult = tf.norm(originalValues.sub(decodedValues), 2).dataSync();

    console.log(normResult);

    if (normResult < THRESHOLD_NORM_RESULT) {
      textArrayResult = [
        "Patient sick",
        "The patient was detected with the disease. You can look at the highlighted events above to see how the algorithm was able to identify it.",
      ];
    } else {
      textArrayResult = [
        "Patient healthy or too sick",
        "The patient was not detected with the disease. The algorithm found that this patient is very different from typical sick patients: he might not have enough decisive events (not sure he's sick), or too much events (too sick).",
      ];
    }

    setResultAE([...textArrayResult]);

    var decodedValuesArray = decodedValues.dataSync();

    // Color key events
    for (var i = 0; i < decodedValuesArray.length; i++) {
      let color = "white";

      if (inputData.current[i] === 1) {
        if (decodedValuesArray[i] > THRESHOLD_PROBA_EVENT) {
          color = "violet";
        }
      }
      oldArrayValue[i] = color;
    }
    setResultArray([...oldArrayValue]);
  }

  // Only runs the prediction if the models are loaded
  function runModelIfAllowed() {
    if (modelsReady) {
      runModel();
    }
  }

  async function loadModel() {
    try {
      // Load pre-trained generative model
      const modelGenerative = await tf.loadLayersModel(
        process.env.PUBLIC_URL + "generative_tfjs/model.json"
      );
      setModelGenerative(modelGenerative);

      // Load pre-trained inference model
      const modelInference = await tf.loadLayersModel(
        process.env.PUBLIC_URL + "inference_tfjs/model.json"
      );
      setModelInference(modelInference);

      setmodelsReady(true);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (!modelsReady) {
      tf.ready().then(() => {
        loadModel();
      })
    }
  }, []);

  return (
    <div className="App">
      <h1>Component for blog article</h1>
      <h2>Is the patient sick?</h2>
      <div className="wrapper-elements">
        <div>
          {" "}
          <h4>Input Data - Patient card</h4>
        </div>
        <div>
          {" "}
          <h4>Output - Key events</h4>{" "}
        </div>
        <div className="container">
          <div></div>
          <div>T1</div>
          <div>T2</div>
          <div>T3</div>
          <div>T4</div>
          <div>
            <span className="label">💊 Treatment A </span>
          </div>
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[0] }}
            onClick={clickOnDiv(0)}></div>{" "}
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[1] }}
            onClick={clickOnDiv(1)}>
            {" "}
          </div>
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[2] }}
            onClick={clickOnDiv(2)}>
            {" "}
          </div>{" "}
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[3] }}
            onClick={clickOnDiv(3)}>
            {" "}
          </div>
          <div>
            <span className="label">💊 Treatment B </span>
          </div>
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[4] }}
            onClick={clickOnDiv(4)}></div>{" "}
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[5] }}
            onClick={clickOnDiv(5)}>
            {" "}
          </div>
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[6] }}
            onClick={clickOnDiv(6)}>
            {" "}
          </div>{" "}
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[7] }}
            onClick={clickOnDiv(7)}>
            {" "}
          </div>
          <div>
            <span className="label">👨‍⚕️ Disease specialist </span>
          </div>
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[8] }}
            onClick={clickOnDiv(8)}>
            {" "}
          </div>{" "}
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[9] }}
            onClick={clickOnDiv(9)}></div>
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[10] }}
            onClick={clickOnDiv(10)}>
            {" "}
          </div>
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[11] }}
            onClick={clickOnDiv(11)}></div>{" "}
          <div>
            <span className="label">👨‍⚕️ General practitioner </span>
          </div>
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[12] }}
            onClick={clickOnDiv(12)}></div>
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[13] }}
            onClick={clickOnDiv(13)}></div>{" "}
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[14] }}
            onClick={clickOnDiv(14)}></div>
          <div
            className="cell-ae"
            style={{ backgroundColor: patientArray[15] }}
            onClick={clickOnDiv(15)}></div>{" "}
        </div>

        <div className="container">
          <div></div>
          <div>T1</div>
          <div>T2</div>
          <div>T3</div>
          <div>T4</div>
          <div>
            <span className="label">💊 Treatment A </span>
          </div>
          <div
            className="cell-ae"
            style={{ backgroundColor: resultArray[0] }}></div>{" "}
          <div className="cell-ae" style={{ backgroundColor: resultArray[1] }}>
            {" "}
          </div>
          <div className="cell-ae" style={{ backgroundColor: resultArray[2] }}>
            {" "}
          </div>{" "}
          <div className="cell-ae" style={{ backgroundColor: resultArray[3] }}>
            {" "}
          </div>
          <div>
            <span className="label">💊 Treatment B </span>
          </div>
          <div className="cell-ae" style={{ backgroundColor: resultArray[4] }}>
            {" "}
          </div>{" "}
          <div className="cell-ae" style={{ backgroundColor: resultArray[5] }}>
            {" "}
          </div>
          <div className="cell-ae" style={{ backgroundColor: resultArray[6] }}>
            {" "}
          </div>{" "}
          <div className="cell-ae" style={{ backgroundColor: resultArray[7] }}>
            {" "}
          </div>
          <div>
            <span className="label">👨‍⚕️ Disease specialist </span>
          </div>
          <div className="cell-ae" style={{ backgroundColor: resultArray[8] }}>
            {" "}
          </div>{" "}
          <div
            className="cell-ae"
            style={{ backgroundColor: resultArray[9] }}></div>
          <div className="cell-ae" style={{ backgroundColor: resultArray[10] }}>
            {" "}
          </div>
          <div
            className="cell-ae"
            style={{ backgroundColor: resultArray[11] }}></div>{" "}
          <div>
            <span className="label">👨‍⚕️ General practitioner </span>
          </div>
          <div
            className="cell-ae"
            style={{ backgroundColor: resultArray[12] }}></div>
          <div
            className="cell-ae"
            style={{ backgroundColor: resultArray[13] }}></div>{" "}
          <div
            className="cell-ae"
            style={{ backgroundColor: resultArray[14] }}></div>
          <div
            className="cell-ae"
            style={{ backgroundColor: resultArray[15] }}></div>{" "}
        </div>

        <div style={{ textAlign: "right" }}>
          <p>
            <button className="btn btn-sm" onClick={generateSickExample}>
              Generate sick patient
            </button>
            <button className="btn btn-sm" onClick={generateHealthyExample}>
              Generate healthy patient
            </button>
          </p>
          <button className="btn btn-primary" onClick={runModelIfAllowed}>
            Test the patient
          </button>
        </div>

        <div style={{ textAlign: "right" }}>
          <h3>{resultAE[0]}</h3> <p> {resultAE[1]}</p>{" "}
        </div>
      </div>
    </div>
  );
}

export default App;
